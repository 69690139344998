<template>
  <div class="flex flex-row h-fit w-full overflow-x-hidden">
        <img v-bind:src="this.Image" alt='' class="hidden md:block" v-if="this.Position == 'LEFT'">
        <div class="flex-1 flex flex-col w-full items-center justify-center mt-8 md:mt-0 md:px-20 px-8">
            <h1 class="font-bold inline text-5xl text-center md:text-justify w-full text-[#009BD6] mb-7">{{ header}}</h1><span class="inline-block"> </span>
            <div class="md:pr-2 flex flex-col">
                <p class="font-bold inline text-center md:text-left text-xl text w-full mb-8">{{Text1}}</p>
                <p class="font-medium inline text-center md:text-left text-base w-full">{{Text2}}</p>
            </div>
        </div>
        <img v-bind:src="this.Image" alt='' class="hidden md:block" v-if="this.Position =='RIGHT'">
  </div>
</template>

<script>
export default {
    name: "BonusRow",
    props: {
        header: String,
        Text1: String,
        Text2: String,
        Image: String,
        Position: String,
    }
}
</script>

<style>

</style>