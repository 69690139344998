import { createApp,h } from 'vue'
import Main from './views/Main.vue'
import Software from './views/Softwares.vue'
import Results from './views/Results.vue'
import App from './App.vue'
import './index.css'
import './assets/tailwind.css'
import * as VueRouter from 'vue-router'




// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.

const routes = [
  { path: '/results', component: Results},
  { path: '/softwares', component: Software },
  { path: '/' , component: Main},
  { path: '/:pathMatch(.*)*', redirect: "/"}
]

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})
const app = createApp({render: ()=>h(App)});
app.use(router)
app.mount('#app')

