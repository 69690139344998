<template>
  <div class="w-screen  bg-bluePlt flex flex-col p-8 md:py-20 md:px-0 items-center space-y-8 md:space-y-16">
      <div class="flex flex-col items-center space-y-4">
          <h1 class="text-4xl font-bold text-center text-white">Iscriviti alla nostra newsletter!</h1>
          <p class="text-sm font-normal text-center text-white">Rimani aggiornato su tutte le novità e promoziondi di PLTNVM</p>
      </div>
      <div class="flex flex-col md:flex-row items-center md:space-x-4 space-y-2 md:space-y-0  w-full justify-center px-8 lg:px-60">
          <input type="email" class="py-4 px-6 rounded-lg font-normal w-full text-base flex flex-1" placeholder="Inserisci qui la tua email">
          <button class="text-white rounded-lg bg-bluePlt w-full flex py-4 flex-1 font-semibold justify-center"> Iscriviti alla newsletter</button>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>