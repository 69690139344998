<template>
    <div>
        <nav class="w-screen h-20 mt-2 flex flex-row items-center m-auto justify-around lg:justify-between">
            <div id="left-nav" class="flex flex-row space-x-2 p-0 bg-white h-8 mx-0 lg:ml-28">
                <router-link to="/" class="mr-8">  <img src='../assets/pltnvmLogo.svg'> </router-link>
                <div id="nav-group" class=" flex-row py-1 items-start space-x-8 justify-start hidden lg:flex">
                    <router-link active-class="border-b-2 border-black" to="/" class=" font-semibold text-sm cursor-pointer">Home</router-link>
                    <router-link active-class="border-b-2 border-black" to="/softwares" class="font-semibold text-sm cursor-pointer">Algoritmi</router-link>
                    <router-link active-class="border-b-2 border-black" to="/results" class="font-semibold text-sm cursor-pointer">Statistiche</router-link>
                    <a class="font-semibold text-sm cursor-pointer" href="https://pltnvmclub.bonuscomparator.com/it/" target="_blank">Bonus siti scommesse</a>
                </div>
            </div>
            <div id="right-nav" class="flex flex-row space-x-12 p-0 bg-white h-8 items-center mx-0 lg:mr-28">
                <div id="social-links" class="hidden lg:flex flex-row space-x-4 p-0 bg-white  h-8">
                    <img src='../assets/Instagram.svg' class="cursor-pointer" @click="openLink('https://www.instagram.com/pltnvm')"><img>
                    <img src='../assets/Telegram.svg' class="cursor-pointer" @click="openLink('https://t.me/pltnvvm')"><img>
                    <img src='../assets/Twitch.svg' class="cursor-pointer" @click="openLink('https://www.twitch.com/pltnvm')"><img>

                </div>
                <div id="store-links" class="flex flex-row space-x-7 p-0 bg-white h-6">
                    <Search class="cursor-pointer hidden lg:hidden "/>
                    <User class="cursor-pointer hidden lg:hidden "/>
                    <Cart class="cursor-pointer hidden lg:hidden"/>
                    <IT class=" flex"/>
                    <img src="../assets/hamburger.svg" alt="" class="lg:hidden cursor-pointer" @click="showMenu = !showMenu">
                </div>
            </div>
        </nav>
        <div class="md:hidden px-8 absolute w-full bg-white transition-transform overflow-hidden origin-top duration-[400ms]" :class="!showMenu ? 'scale-y-0' : 'scale-y-100'">
            <div class="w-full h-auto bg-white items-left  gap-y-4 flex flex-col mb-11">
                <router-link active-class="border-b-2 border-black" to="/" class="font-semibold text-base">Home</router-link>
                <router-link to="/softwares" active-class="border-b-2 border-black" class="font-semibold text-base">Software</router-link>
                <router-link to="/results" active-class="border-b-2 border-black" class="font-semibold text-base">Statistiche</router-link>
                <a class="font-semibold text-sm cursor-pointer" href="https://pltnvmclub.bonuscomparator.com/it/" target="_blank">Siti compatibili</a>
            </div>
            <div class="w-full flex flex-row gap-x-6 mb-8">
                <img src='../assets/Instagram.svg' class="cursor-pointer  h-16 w-16" @click="openLink('https://www.instagram.com/pltnvm')"><img>
                <img src='../assets/Telegram.svg' class="cursor-pointer  h-16 w-16" @click="openLink('https://t.me/+966xVpoltBk1ZDI0')"><img>
                <img src='../assets/Twitch.svg' class="cursor-pointer  h-16 w-16" @click="openLink('https://www.twitch.com/pltnvm')"><img>

        </div>
        </div>
    </div>
</template>
<script>
import Cart from '../assets/Cart.vue'
import IT from '../assets/IT.vue'
import Search from '../assets/Search.vue'
import User from '../assets/User.vue'
export default {
    name : "NavBar",
    props : {

    },
    components:{
        Search,
        User,
        Cart,
        IT
    },
    methods: {
        openLink(url) {
            window.open(url, '_blank').focus();
        }
    },
    data(){
        return{
            showMenu : false
        }
    }
}
</script>
