import '../assets/stats.css';

const Statistiche = () => {
  return (
    <div style=" height: auto;">
      <h1 id="titolo2"><b>STAGIONE PLATINUM 2023/2024</b></h1>
      <p id="paragrafo2" style="padding: 20px; text-align:left; color:#202020"><b>Benvenuti alla sezione delle statistiche!</b> Qui vi immergerete nel mondo affascinante dei dati e delle previsioni. I nostri algoritmi all'avanguardia sono pronti ad entrare in azione ogni giorno, elaborando una vasta gamma di informazioni per generare pronostici accurati e utili. Ogni pronostico prodotto verrà attentamente registrato, consentendoci di valutare le prestazioni passate e migliorare costantemente le nostre capacità predictive. 
</p>

      <div className="tab">
        <button className="tablinks" onClick={() => openCity('London')}>Settembre</button>
      </div>

      <div id="London" className="tabcontent">
      <div class="diosanto">
      <h1 id="titolo2"><b>Settembre</b></h1>
      
  
    <div class="rettangoloxd">
        <div class="listresult">
        <div class="result1">
              <div class="subresult">
                <h1 class="info not">DATA</h1> 
                <h1 class="info2" style="background:white; color: #262626;">MATCH</h1> 
                <h1 class="info">TIP</h1> 
                <h1 class="info">QUOTA</h1> 
                <h1 class="info not">STAKE</h1> 
                <h1 class="info">PROFITTO</h1>
            </div>
        </div>
          <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">27/08/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/ELaCrwgf/#/informazioni-partita/informazioni-partita">Napoli - Sassuolo</a></h1> 
                <h1 class="info">GOAL</h1> 
                <h1 class="info">1.85</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">8.5%</h1> 
        
            </div>
        </div>
        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">27/08/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/UZ2EMQpG/#/informazioni-partita/informazioni-partita">Magonza - Francoforte</a></h1> 
                <h1 class="info">GOAL</h1> 
                <h1 class="info">1.85</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">8.5%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">27/08/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/pOdMz9dK/#/informazioni-partita/informazioni-partita">Nizza - Lione</a></h1> 
                <h1 class="info">x2</h1> 
                <h1 class="info">1.71</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">7.1%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">27/08/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/4nAxkuGQ/#/informazioni-partita/informazioni-partita">Lazio - Genoa</a></h1> 
                <h1 class="info">1</h1> 
                <h1 class="info">1.65</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1lose">10%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">28/08/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/dpkHsc80/#/informazioni-partita/informazioni-partita">Salernitana - Udinese</a></h1> 
                <h1 class="info">GOAL</h1> 
                <h1 class="info">1.85</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">8.5%</h1> 
        
            </div>
          </div>

          <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">01/09/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/zkTgeGx0/#/informazioni-partita/informazioni-partita">Sassuolo - Verona</a></h1> 
                <h1 class="info">x2</h1> 
                <h1 class="info">1.79</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1lose">-10%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">01/09/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/dbUkddNg/#/informazioni-partita/informazioni-partita">Roma - Milan</a></h1> 
                <h1 class="info">GOAL</h1> 
                <h1 class="info">1.87</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">10%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">02/09/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/zHivdjZC/#/informazioni-partita/informazioni-partita">Brighton - Newcastle</a></h1> 
                <h1 class="info">1</h1> 
                <h1 class="info">1.85</h1> 
                <h1 class="info not">6%</h1> 
                <h1 class="info1">5%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">02/09/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/vNZpcx8m/#/informazioni-partita/informazioni-partita">Napoli - Lazio</a></h1> 
                <h1 class="info">GOAL</h1> 
                <h1 class="info">1.91</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">9%</h1> 
        
            </div>
        </div>

        <div class="result1">
              <div class="subresult">
               
                <h1 class="info not">02/09/2023</h1> 
                <h1 class="info2"><a href="https://www.diretta.it/partita/rFW1gf7C/#/informazioni-partita/informazioni-partita">Udinese - Frosinone</a></h1> 
                <h1 class="info">Under 2.5</h1> 
                <h1 class="info">1.87</h1> 
                <h1 class="info not">10%</h1> 
                <h1 class="info1">9%</h1> 
        
            </div>
        </div>
        
    </div>
</div>
  </div>   
      </div>


    </div>
  );
};

function openCity(cityName) {
  const tabcontent = document.getElementsByClassName("tabcontent");
  for (let i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  const tablinks = document.getElementsByClassName("tablinks");
  for (let i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  const cityElement = document.getElementById(cityName);
  if (cityElement) {
    cityElement.style.display = "block";
  }
}

export default Statistiche;
