<template>
  <div class="w-screen h-screen overflow-x-hidden" id="#app">
   
    <CookieConsent></CookieConsent>
    <NavBar></NavBar>
    <Hero></Hero>
    <Info></Info>
    <Subscriptions></Subscriptions>
    <Grafo></Grafo>
    <InfoSoftware></InfoSoftware>
    <Footer></Footer>
 </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import NavBar from '../components/NavBar.vue'
import Info from '../components/Info.vue'
import Subscriptions from '../components/Subscriptions.vue'
import Grafo from '../components/Grafo.vue'
import InfoSoftware from '../components/InfoSoftware.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Main',
  components: {
    
    NavBar,
    Hero,
    Info,
    Subscriptions,
    Grafo,
    InfoSoftware,   
    Footer,
  },
  data(){
    return{
      show : true
    }
  }
}
</script>

<style>

</style>
