<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.00008 5.414L0.75708 2.172L2.17208 0.757004L5.41408 4H20.6561C20.8119 3.99999 20.9657 4.03642 21.1049 4.10637C21.2442 4.17632 21.3652 4.27787 21.4583 4.4029C21.5514 4.52793 21.6139 4.67299 21.641 4.82649C21.668 4.97999 21.6588 5.13769 21.6141 5.287L19.2141 13.287C19.1523 13.4931 19.0258 13.6738 18.8532 13.8022C18.6806 13.9307 18.4712 14 18.2561 14H6.00008V16H17.0001V18H5.00008C4.73486 18 4.48051 17.8946 4.29297 17.7071C4.10544 17.5196 4.00008 17.2652 4.00008 17V5.414ZM6.00008 6V12H17.5121L19.3121 6H6.00008ZM5.50008 22C5.10226 22 4.72072 21.842 4.43942 21.5607C4.15812 21.2794 4.00008 20.8978 4.00008 20.5C4.00008 20.1022 4.15812 19.7206 4.43942 19.4393C4.72072 19.158 5.10226 19 5.50008 19C5.8979 19 6.27944 19.158 6.56074 19.4393C6.84204 19.7206 7.00008 20.1022 7.00008 20.5C7.00008 20.8978 6.84204 21.2794 6.56074 21.5607C6.27944 21.842 5.8979 22 5.50008 22ZM17.5001 22C17.1023 22 16.7207 21.842 16.4394 21.5607C16.1581 21.2794 16.0001 20.8978 16.0001 20.5C16.0001 20.1022 16.1581 19.7206 16.4394 19.4393C16.7207 19.158 17.1023 19 17.5001 19C17.8979 19 18.2794 19.158 18.5607 19.4393C18.842 19.7206 19.0001 20.1022 19.0001 20.5C19.0001 20.8978 18.842 21.2794 18.5607 21.5607C18.2794 21.842 17.8979 22 17.5001 22Z" fill="#131D5D"/>
    </svg>
</template>

<script>
export default {
    name : "CartSVG",
    props : {

    },
    components:{
    }
}
</script>