<template>
    <div class="main">
 <div class="contenitore">
      <h1 class="titolografico"> VANTAGGI DEL PREMIUM PASS</h1>
    <div class="containerbar">
      <div class="container1">
        <img id="time" src="../assets/tempo.svg" alt="timing">
        <div class="rettangolo">
          <div class="gruppetto1">
            <h1 class="titolo">Guadagna tempo lasciando il lavoro al tuo investimento</h1>
            <p class="descrizione">Attraverso i nostri Algoritmi, non sarà più necessario pensare a cosa giocare, basterà semplicemente cliccare su “Genera proposta” per ottenere la proposta più "sensata" da seguire!</p>
        </div>
      </div>
    </div>
    <div class="container1">
        <img id="time" src="../assets/stats.svg" alt="timing">
        <div class="rettangolo">
          <div class="gruppetto">
            <h1 class="titolo">Investi seguendo tempistiche e Staking consigliati </h1>
            <p class="descrizione">Analisi Statistica applicata non solo a criteri di generazione pronostici, ma soprattutto a livello di Budget Management e Timing. Trovare regolarità a Nostro dire è il primo Step per ottenere continuità sul lungo termine.</p>
        </div>
      </div>
    </div>
    <div class="container1">
        <img id="time" src="../assets/ai.svg" alt="timing">
        <div class="rettangolo">
          <div class="gruppetto">
            <h1 class="titolo">Impara a gestire il tuo Mindset durante le sessioni di investimento</h1>
            <p class="descrizione">Mindset significa letteralmente <b>“Mentalità”</b>, ed è noto che in un settore come quello del gioco d’azzardo, sia difficile da gestire soprattutto sul lungo termine. 
<b>L’obiettivo primario è proprio questo, saper mantenere la giusta freddezza in fase di scelta ed investimento.</b></p>
        </div>
      </div>
    </div>

    </div>
  

</div>
</div>
</template>
<style>

@import url(../assets/stylescss.css);

</style>