<template>
	
	<div class="main1">               
		<section class="container2">
	<h1 id="titolot"> WIN RATE ATTUALE </h1>
	<p id="descris">I grafici riassumono i risultati ottenuti durante il periodo 2021-2022 </p>
	<div class="stats">
	
		<img class="statsimage" src="../assets/rouletta.png">
		<img class="statsimage" src="../assets/Sport.png">
		
	</div>
	<div class="jello">
	
</div>
</section>
        <section class="container">
			<h1 id="titolot"> SITI SCOMMESSE COMPATIBILI CON I NOSTRI ALGORITMI </h1>	
			<p id="descris">Ogni book disponibile nello slider e nel bonus comparator è compatibile con la PLATINUM EXPERIENCE</p>
	<div class="slider-wrapper">
	<div class="slider">
	<a id="img6" href="https://www.gambling-affiliation.com/cpc/v=Hvu3txnLhTpXxWn3qLG88RrTIhwJmV9wgUOR.wZra9M_GA7331V2"><img id="slide-7" src="../assets/goldbet.jpg" alt="goldbet" /></a>
	<a id="img7" href="https://www.gambling-affiliation.com/cpc/v=8He7.Ag8XtIsnikDfR6j09UVHhTXNGOeLigUPsINu1I_GA7331V2"><img id="slide-8" src="../assets/chescommessa.png" alt="chescommessa" /></a>
      <a id="img1" href="https://betly.co/c25e119027"><img id="slide-1" src="../assets/leovegas.png" alt="leovegas" /></a>
      <a id="img2" href="https://betly.co/39b885436"><img id="slide-2" src="../assets/eurobet.png" alt="eurobet" /></a>
      <a id="img3" href="https://ads.sisal.it/promoRedirect?key=ej0xMzUyNDE2MyZsPTE0MTcyMTk1JnA9MTAwMzY1"><img id="slide-4" src="../assets/sisal.png" alt="sisal" /></a>
      <a id="img4" href="https://betly.co/a8d5827886"><img id="slide-5" src="../assets/netbet.png" alt="netbet" /></a>
      <a id="img5" href="https://betly.co/826e632851"><img id="slide-6" src="../assets/pokerstars.png" alt="Pokerstars" /></a>
	</div>
		<div class="slider-nav">
			<a href="#img7"></a>
			<a href="#img6"></a>
			<a href="#img1"></a>
			<a href="#img2"></a>
      <a href="#img3"></a>
      <a href="#img4"></a>
      <a href="#img5"></a>
		</div>
		
	</div>
</section>


</div>

</template>

<script>
export default {
  data(){
    return{
     expand : false
    }
  }
}
</script>

<style>

@media(min-width: 1281px){
	.jello{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hellobaby{
		margin-top: 15px;
		font-family: "Inter";
		display:block;

	}
.main1{
	margin-top: 200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
margin-right: 30px;

-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{

	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 60%;
}
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.jello{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main1{
	margin-top: 200px;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
	
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
margin-right: 30px;

-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{
	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	margin-bottom: 2.5rem;
	margin-left: 30px;
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 60%;
	padding: 15px;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.main1{
	margin-top: 200px;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
	
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
margin-right: 30px;

-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{
	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	margin-bottom: 2.5rem;
	margin-left: 30px;
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 60%;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.main1{
	margin-top: 200px;
	margin-bottom: 7rem;
	padding-left: 8%;
	padding-right: 8%;
	
}
.container{

padding: 20px;
border: grey;
border-radius: 20px;
width: 80%;


-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
.container2{
	padding: 20px;
	border: grey;
	border-radius: 20px;
	margin-right: 30px;
	margin-bottom: 2.5rem;
	margin-left: 30px;
-webkit-box-shadow:0px 0px 24px 0px #c0c0c0 ;
-moz-box-shadow:0px 0px 24px 0px #c0c0c0 ;
box-shadow:0px 0px 24px 0px #c0c0c0 ;
}
#descris{
	text-align: center;
	padding-bottom: 40px;
	font-family: 'Inter';
	font-size: 18px;
}
#titolot{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 35px;
text-align: center;
font-feature-settings: 'liga' off;
color: #000000;
padding-bottom: 2rem;
}


.slider-wrapper {
	position: relative;
	max-width: 28rem;
	margin: 0 auto;
  
}

.slider {
	display: flex;
	aspect-ratio: 16 / 9;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
	border-radius: 0.5rem;
    overflow-x: scroll;
}

.slider img {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
}
#img1 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img2 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img3 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img4 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img5 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img6 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
#img7 {
	flex: 1 0 100%;
	scroll-snap-align: start;
	object-fit: cover;
  cursor:pointer;
  overflow-y:hidden;
}
.slider-nav {
	display: flex;
	column-gap: 1rem;
	position: absolute;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.slider-nav a {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.75;
	transition: opacity ease 250ms;
}

.slider-nav a:hover {
	opacity: 1;
}
#img1 .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #00415A;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.stats{
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.statsimage{
	width: 40%;
}
}




</style>