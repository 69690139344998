<template>
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5"/>
    <mask id="mask0_484_3059" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5"/>
    </mask>
    <g mask="url(#mask0_484_3059)">
    <rect x="18.6667" width="9.33333" height="20" fill="#E43D4C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 20H9.33333V0H0V20Z" fill="#1BB65D"/>
    </g>
    </svg>
</template>
<script>
export default {
    name : "ITSVG",
    props : {

    },
    components:{
    }
}
</script>
