<template>
	<div id="subscriptions" class="w-screen min-h-screen flex flex-col bg-[#2B43E00D] items-center p-8 lg:py-20 lg:px-28 space-y-12">
		<h1 class="text-4xl font-bold leading-10">Pacchetti Premium</h1>
		<div id="plans" class="flex flex-col lg:flex-row min-h-[80vh] w-full lg:space-x-8 gap-y-8 ">
			<div id="container1" class="bg-white shadow-xl w-full my-3 rounded-2xl flex flex-col md:flex-row lg:flex-col justify-between items-center gap-y-8 py-10 md:py-6 lg:py-10 px-10 md:px-6 lg:px-10">
				<img src="../assets/newlogoazzurro.svg" class="md:hidden lg:block"/>
				<div class="flex flex-col items-center justify-between gap-y-8 md:gap-y-0 lg:gap-y-8">
					<h1 class='text-black text-3xl md:text-sm lg:text-3xl text-center md:text-left w-full font-bold'>PREMIUM PASS</h1>
					<p class='rounded-3xl bg-[#2B43E01A] py-2 w-full text-center text-xs font-bold md:hidden lg:block'>24 ore di utilizzo</p>
					<div id="prices" class= 'space-y-2 flex flex-col items-center md:items-left lg:items-center w-full'>

						<p class='md:text-2xl text-4xl lg:text-4xl font-bold w-full text-center md:text-left lg:text-center'>€4,90</p>
					</div>
				</div>
				<p class="text-center font-medium text-base">Il <b>Premium pass giornaliero, consente all'utente di accedere agli algoritmi (Sport&Roulette) sbloccati per 24h dall'attivazione.</b> Non prevede l'accesso al materiale completo, ma al materiale di base per il corretto utilizzo.</p>
				<button id="buy" @click="load('https://buy.stripe.com/3cs16FcWZ6Rlh20dQT')" class='rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 py-4 px-6 text-white font-semibold text-base cursor-pointer buttone'>ACQUISTA</button>
			</div>
			<div id="container2" class="bg-gradient-to-r from-sky-500 to-indigo-500 shadow-xl w-full rounded-2xl flex flex-col md:flex-row lg:flex-col justify-between items-center gap-y-8 py-10 md:py-6 lg:py-10 px-10 md:px-6 lg:px-10">
				<img src="../assets/Logobianco.svg" class="md:hidden lg:block"/>
					<div class="flex flex-col items-center gap-y-8 md:gap-y-0 lg:gap-y-8">
						<h1 class='text-white text-3xl md:text-sm lg:text-3xl text-center md:text-left w-full font-bold'>PREMIUM PASS</h1>
						<p class='rounded-3xl bg-[#FFFFFF] bg-opacity-20 py-2 w-full text-center text-xs font-bold text-white md:hidden lg:block'>Tutta la stagione</p>
						<div id="prices" class= 'space-y-2 flex flex-col items-center md:items-left lg:items-center w-full'>
							<p class='md:text-xs text-lg lg:text-lg font-medium line-through w-full text-center md:text-left lg:text-center text-white '>€238,80</p>
							<p class='md:text-2xl text-4xl lg:text-4xl font-bold w-full text-center md:text-left lg:text-center text-white'>€199,90</p>
						</div>
					</div>
				<p class="text-center font-medium text-base leading-6 text-white">Per i giocatori che cercano un <b>impegno a lungo termine e risultati duraturi </b>, il nostro <b>Premium Pass Annuale</b> è la scelta ideale. Godi dell'accesso esclusivo agli <b>algoritmi sbloccati per un'intero anno </b>, . Se vuoi avvicinarti al gioco con <b>intelligenza e precisione </b>, il Premium Pass Annuale ti offre una visione approfondita dei nostri algoritmi (Sport&Roulette) ed un sistema di gestione del budget semplificato. </p>
				<button id="buy" @click="load('https://buy.stripe.com/9AQ2aJ1ehcbF6nmcMQ')" class='rounded-lg bg-white py-4 px-6 text-indigo-500 font-semibold text-base cursor-pointer buttone'>ACQUISTA</button>

			</div>
			<div id="container3" class="bg-white shadow-xl w-full my-3 rounded-2xl flex flex-col md:flex-row lg:flex-col justify-between items-center gap-y-8 py-10 md:py-6 lg:py-10 px-10 md:px-6 lg:px-10">
						<img src="../assets/newlogoazzurro.svg" class="md:hidden lg:block"/>
						<div class="flex flex-col items-center justify-between gap-y-8 md:gap-y-0 lg:gap-y-8">
							<h1 class='text-3xl md:text-sm lg:text-3xl md:text-left w-full font-bold text-center'>PREMIUM PASS</h1>
							<p class='rounded-3xl bg-[#2B43E0] text-black bg-opacity-10 py-2 px-4 w-full text-center text-xs font-bold md:hidden lg:block'>30 giorni di fuoco</p>
							<div id="prices" class= 'space-y-2 flex flex-col items-center md:items-left lg:items-center w-full'>
									<p class='md:text-xs text-lg lg:text-lg font-medium line-through w-full text-center md:text-left lg:text-center'><br></p>
									<p class='md:text-2xl text-4xl lg:text-4xl font-bold w-full text-center md:text-left lg:text-center'>€19,90</p>
							</div>
						</div>
						<p class="text-center font-medium text-base"><b>Premium pass mensile</b> è dedicato a coloro che vogliono interfacciarsi al gioco <b>sporadicamente</b>, ma che comunque rimane una <b>passione e la voglia di sperimentare nuovi metodi</b>. Il pass mensile è il nostro invito ad un'ottica a lungo termine, per raggiungere obiettivi proporzionali al periodo d'investimento.</p>
						<button id="buy" @click="load('https://buy.stripe.com/4gwbLj7CF4Jd6nm7sx')" class='rounded-lg bg-gradient-to-r from-sky-500 to-indigo-500 py-4 px-6 text-white font-semibold text-base cursor-pointer buttone'>ACQUISTA</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
methods: {
    load(url){
        window.open(url,'_blank')
    }
}
};
</script>

<style>
</style>
